<template>
<span class="has-text-right">
    <p class="has-text-weight-light has-text-grey info-label">
        <small>
            <em>{{ label }}</em>
        </small>
    </p>
    <span class="has-text-weight-bold is-flex is-align-items-center is-justify-content-space-between">
        <icon class="has-text-grey info-icon" v-if="icon" :icon="icon" />
        {{ info }}
    </span>
</span>
</template>
<script>
export default {
    props: {
        label: String,
        info: [String, Number],
        icon: String
    }
}
</script>